<template>
    <div>
        <div class="main-title">图书库存</div>
        <div class="content-list-page page-content">
            <div class="list-head-box">
                <div data-v-69874ad0="" class="list-head">
                    <p data-v-69874ad0="" class="list-head-title">温馨提示</p>
                    <ul data-v-69874ad0="">
                        <li data-v-69874ad0="">封面大小：960*720</li>
                    </ul>
                </div>
            </div>


            <div class="padding-t-sm padding-b-sm">

                <span class="margin-l-sm fl-l">
                    <el-select v-model="searchData.status" clearable placeholder="状态" size="small">
                        <el-option v-for="item in types" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </span>

                <span class=" margin-l-sm fl-l ">
                    <el-input v-model.trim="searchData.name" placeholder="课程名称" clearable size="small"></el-input>
                </span>

                                <span class="margin-l-sm fl-l" >
                                    <el-date-picker
                                            size="small"
                                            v-model="value2"
                                            type="daterange"
                                            value-format="yyyy-MM-dd"
                                            align="right"
                                            unlink-panels
                                            range-separator="至"
                                            start-placeholder="开始日期"
                                            end-placeholder="结束日期"
                                            :picker-options="pickerOptions">
                                    </el-date-picker>
                                </span>

                <span class="margin-l-sm fl-l">
                    <el-button type="primary" icon="el-icon-search" size="small" @click="search">搜索</el-button>
                </span>

                <span class=" fl-r">
                    <router-link to="/stock/add" class="router-link">
                        <el-button type="primary" size="mini">新建图书</el-button>
                    </router-link>
                    <el-button icon="el-icon-download" class="margin-l-sm" type="primary"
                               @click="exportData"
                               size="mini">导出</el-button>
                </span>

                <div style="clear: both"></div>

            </div>


            <div class="table-content">

                <el-table :header-cell-style="{ padding: 0 }" ref="singleTable" :data="tableData" highlight-current-row v-loading="loading" style="width: 100%;background: #fff;">
                    <el-table-column property="id" label="Id" width="80">
                    </el-table-column>
                    <el-table-column label="封面">
                        <template slot-scope="scope">
                            <el-image class="table-item-img " :src="imageUrl + scope.row.pic">
                                <div slot="error" class="image-slot">
                                    <i class="el-icon-picture-outline"></i>
                                </div>
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column property="index" label="名称"  width="200">
                        <template slot-scope="scope">
                            <span>{{ scope.row.name }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column property="created_at" label="创建时间">
                    </el-table-column>
                    <el-table-column property="stock_nums" label="现有库存">
                    </el-table-column>
                    <el-table-column property="index" label="状态">
                        <template slot-scope="scope">
                            <el-tag :type="scope.row.status == 1 ? 'success' : 'danger'" size="mini">{{ scope.row.status
                                    ==
                                    1 ? '上架' : '下架'
                            }}</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column property="address" label="操作">
                        <template slot-scope="scope">
                            <span>
                                <el-link slot="reference" type="primary" @click="editDetail(scope.row)">编辑</el-link>
                            </span> -
                            <span>
                                <el-link slot="reference" type="primary" @click="courseDetail(scope.row)">详情</el-link>
                            </span> -
                            <span>
                                <el-popconfirm icon="el-icon-info" icon-color="red" title="删除后将不可恢复，确定删除吗？"
                                    @confirm="deleted(scope.row)">
                                    <el-link slot="reference" type="primary">删除</el-link>
                                </el-popconfirm>
                            </span>-
                            <span>
                                <el-link slot="reference" type="primary" @click="stockChange(scope.row)">出入库</el-link>
                            </span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>

            <div class="table-batch">
                <span class="fl-r">
                    <el-pagination small background :current-page="page" :page-sizes="[10, 20]" :page-size="pageSize"
                        layout="total, sizes, prev, pager, next, jumper" @size-change="setPageSize"
                        @current-change="setPage" :total="total">
                    </el-pagination>
                </span>
                <div style="clear: both"></div>
            </div>
        </div>
        <el-dialog title="出入库" append-to-body :visible.sync="dialogVisible" width="40%">
            <el-form :model="form" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
                <el-form-item label="出入库" label-position="left" prop="type">
                    <el-radio-group v-model="form.type">
                        <el-radio-button label="出库"></el-radio-button>
                        <el-radio-button label="入库"></el-radio-button>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="进出库数量" label-position="left" prop="nums">
                    <el-input style="width: 200px" v-model.number="form.nums"></el-input>
                </el-form-item>
                <el-form-item label="进出库时间" label-position="left" prop="stockAt">
                    <el-date-picker v-model="form.stockAt" type="datetime" placeholder="选择日期时间" default-time="12:00:00">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="备注" label-position="left">
                    <el-input style="width: 200px" v-model="form.remarks"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')">保存</el-button>
                    <el-button @click="dialogVisible = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "template-list",
    data() {
        return {
            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                        picker.$emit('pick', [start, end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                        const end = new Date();
                        const start = new Date();
                        start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                        picker.$emit('pick', [start, end]);
                    }
                }]
            },
            value2:'',
            //对话框表单验证
            rules: {
                nums: [
                    { required: true, message: '请输入库存数量', trigger: 'blur' },
                    { type: 'number', message: '库存必须为数字值' }
                ],
                type: [
                    { required: true, message: '请选择图书状态', trigger: 'change' },
                ],
                stockAt: [
                    { type: 'date', required: true, message: '请选择日期', trigger: 'change' }
                ],
            },
            //对话框
            dialogVisible: false,
            //图片前缀
            imageUrl: config.imageUrl,
            //搜索的数据
            searchData: {
                status: '',
                name: '',
                startDate:'',
                endDate:''
            },
            //搜索的状态
            types: [
                {
                    id: 1,
                    name: '上架'
                },
                {
                    id: 2,
                    name: '下架'
                }
            ],
            //列表的数据加载动画
            loading: false,
            //表格的数据
            tableData: [],
            //分页
            page: 1,
            //每页条数
            pageSize: 10,
            //总条数
            total: 0,
            //出入口图书
            form: {
                type: "出库"
            },
        }
    },
    methods: {
        ...mapActions('store', ['getBookStockList', 'delBookStock', 'changeBookStock','exportBookStock']),
        //搜索的方法
        search() {
            this.initData()
        },
        //切换分页的方法
        setPageSize(pageSize) {
            this.pageSize = pageSize
            this.initData()
        },
        //分页数据的方法
        setPage(page) {
            this.page = page
            this.initData()
        },
        //编辑
        editDetail(row) {
            this.$router.push('/stock/edit/' + row.id)
        },
        //删除
        deleted(row) {
            this.delBookStock(row.id).then(res => {
                if (res.res_info == 'ok') {
                    this.initData()
                    this.$message.success('删除成功')
                }
            })
        },
        //详情
        courseDetail(row) {
            this.$router.push('/stock/details/' + row.id)
        },
        //出入库
        stockChange(row) {
            this.form = {
                type: "出库"
            }
            this.dialogVisible = true
            this.form.bookStockId = row.id
            this.form.stock_nums = row.stock_nums
        },
        //出入库数据保存
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    let form = JSON.parse(JSON.stringify(this.form))
                    form.type = form.type == "出库" ? 2 : 1
                    if (form.type == 2 && form.stock_nums < form.nums) {
                        this.$message.error('出库的数量不能大于原始库存')
                        return
                    }
                    this.changeBookStock(form).then(res => {
                        this.dialogVisible = false
                        this.initData()
                    })
                } else {
                    return false;
                }
            })
        },
        //初始化数据的方法
        initData() {
            let data = {
                ...this.searchData,
                page: this.page,
                pageSize: this.pageSize,
            }
            this.loading = true
            this.getBookStockList(data).then(res => {
                this.total = res.data.total
                this.tableData = res.data.list
                this.loading = false
            })
        },
        //导出
        exportData(){
            if(this.value2.length > 0){
                this.searchData.startDate = this.value2[0]
                this.searchData.endDate = this.value2[1]
            }
            let data = {
                ...this.searchData
            }
            this.exportBookStock(data).then(res => {
                if (res.ret === 0) {
                    this.$message.success('添加到队列中')
                    // window.location.href = config.serviceUrl + '/download?fileName=' + res.data.fileName
                }
            })
        }
    },
    created() {
        this.initData()
    }
}
</script>
